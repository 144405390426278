@keyframes bg-img-anim {
  0% {
    background-position: 0 0; }
  100% {
    background-position: 148px 0; } }
	
	@keyframes bounce-scale {
  0%, 20%, 50%, 80%, 100% {
    transform: scale(1); }
  20% {
    transform: scale(1.2); }
  80% {
    transform: scale(1.05); } }

@keyframes ease-out-back {
  0% {
    transform: scale(1); }
  10% {
    transform: scale(0.9); }
  60% {
    transform: scale(1.2); }
  80% {
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }

@keyframes arrow-bounce {
  0%, 20%, 50%, 80%, 100% {
    right: 0; }
  40% {
    right: 10px; }
  60% {
    right: -5px; } }

@keyframes sm-bounce-scale {
  0%, 20%, 50%, 80%, 100% {
    transform: scale(0.8); }
  20% {
    transform: scale(1); }
  80% {
    transform: scale(0.85); } }

.movement {
   display: block;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   z-index: 0; }
.movement-inner {
   display: block;
   width: 100%;
   height: 100%;
   position: absolute;
   top: 0;
   left: 0;
   background: transparent url("../images/Header/hexagon-fade-down.png") repeat-x 0 0; 
	
   animation-name: bg-img-anim;
   animation-duration: 7s;
   animation-iteration-count: infinite;
   animation-timing-function: linear;
   animation-fill-mode: forwards;
   animation-delay: 0; 
}