
  .nft{
    width: 90%;
    margin: 1rem;
    border: 1px solid #ffffff22;
    background-color: #282c34;
    background: linear-gradient(0deg, rgba(40,44,52,1) 0%, rgba(17,0,32,.5) 100%);
    box-shadow: 0 7px 20px 5px #00000088;
    border-radius: 1rem;
    box-shadow: 0px -16px 24px -15px rgba(255, 255, 255, 0.25) inset;
    overflow: hidden;
    transition: .5s all;
    padding: 0.7rem;
    .main{
      max-width: 100%;
      padding: 1rem;
    }
    &:hover{
      border: 1px solid #ffffff44;
      box-shadow: 0 7px 50px 10px #000000aa;
      transform: scale(1.015);
      
    box-shadow: 0px -16px 24px 0px rgba(255, 255, 255, 0.25) inset;
      ::before{
        top: -100%;
        left: 200%;
      }
    }
  }
  
  .bg-container{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .container-line {
    width: 100%;
    height: 0.01rem;
    background-color: hsla(240, 10%, 90%, 0.5);
    border: none;
  }