.container-info {
    --white: hsl(0, 0%, 100%);
    --black: hsl(240, 15%, 9%);
    --paragraph: hsl(0, 0%, 83%);
    --line: hsl(240, 9%, 17%);
    --primary: hsl(266, 92%, 58%);
  
    position: relative;
  
    display: flex;
    flex-direction: column;
    gap: 1rem;
  
    padding: 1rem;
    width: 100%;
    background-color: hsla(240, 15%, 9%, 1);
    background-image: radial-gradient(
        at 88% 40%,
        hsla(240, 15%, 9%, 1) 0px,
        transparent 85%
      ),
      radial-gradient(at 49% 30%, hsla(240, 15%, 9%, 1) 0px, transparent 85%),
      radial-gradient(at 14% 26%, hsla(240, 15%, 9%, 1) 0px, transparent 85%),
      radial-gradient(at 0% 64%, hsla(263, 93%, 56%, 1) 0px, transparent 85%),
      radial-gradient(at 41% 94%, hsla(284, 100%, 84%, 1) 0px, transparent 85%),
      radial-gradient(at 100% 99%, hsla(306, 100%, 57%, 1) 0px, transparent 85%);
  
    border-radius: 1rem;
    box-shadow: 0px -16px 24px 0px rgba(255, 255, 255, 0.25) inset;
  }
  
  .container-info .container-info__border {
    overflow: hidden;
    pointer-events: none;
  
    position: absolute;
    z-index: -10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    background-image: linear-gradient(
      0deg,
      hsl(0, 0%, 100%) -50%,
      hsl(0, 0%, 40%) 100%
    );
  
    border-radius: 1rem;
  }
  
  .container-info .container-info__border::before {
    content: "";
    pointer-events: none;
  
    position: fixed;
    z-index: 200;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%), rotate(0deg);
    transform-origin: left;
  
    width: 200%;
    height: 10rem;
    background-image: linear-gradient(
      0deg,
      hsla(0, 0%, 100%, 0) 0%,
      hsl(277, 95%, 60%) 40%,
      hsl(277, 95%, 60%) 60%,
      hsla(0, 0%, 40%, 0) 100%
    );
  
    animation: rotate 8s linear infinite;
  }
  
  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
  
  .container-info .container-info_title__container .container-info_title {
    font-size: 1rem;
    color: var(--white);
  }
  
  .container-info .container-info_title__container .container-info_paragraph {
    margin-top: 0.25rem;
    width: 100%;
    font-size: 0.8rem;
    color: var(--paragraph);
    margin-bottom: 0px;
    height: 50px;
  }
  
  .container-info .line {
    width: 100%;
    height: 0.1rem;
    background-color: var(--line);

    border: none;
  }
  
  .container-info .container-info__list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .container-info .container-info__list .container-info__list_item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .container-info .container-info__list .container-info__list_item .check {
    display: flex;
    justify-content: center;
    align-items: center;
  
    width: 1rem;
    height: 1rem;
    background-color: var(--primary);
  
    border-radius: 50%;
  }
  
  .container-info .container-info__list .container-info__list_item .check .check_svg {
    width: 0.75rem;
    height: 0.75rem;
  
    fill: var(--black);
  }
  
  .container-info .container-info__list .container-info__list_item .list_text {
    font-size: 0.75rem;
    color: var(--white);
  }
  
  .container-info .button {
    cursor: pointer;
  
    padding: 0.5rem;
    width: 100%;
    background-image: linear-gradient(
      0deg,
      rgba(94, 58, 238, 1) 0%,
      rgba(197, 107, 240, 1) 100%
    );
  
    font-size: 0.75rem;
    color: var(--white);
  
    border: 0;
    border-radius: 9999px;
    box-shadow: inset 0 -2px 25px -4px var(--white);
  }

.container-ribbon
{
    align-items: center;
}

.container-text
{
    color: hsl(0, 0%, 100%);
    margin-top: 0.25rem;
    font-size: 1rem;
    margin-bottom: 0px;
}

.container-title
{
    font-size: 1.5rem;
    color: hsl(0, 0%, 100%);
    font-weight: bold;
}

.container-card {
  width: auto;
  height: auto;
  position: relative;
  background-image: linear-gradient(230deg, rgba(93, 93, 93, 0.03) 0%, rgba(93, 93, 93, 0.03) 50%, rgba(78, 78, 78, 0.03) 50%, rgba(78, 78, 78, 0.03) 100%), linear-gradient(107deg, rgba(55, 55, 55, 0.01) 0%, rgba(55, 55, 55, 0.01) 50%, rgba(170, 170, 170, 0.01) 50%, rgba(170, 170, 170, 0.01) 100%), linear-gradient(278deg, rgba(16, 16, 16, 0.03) 0%, rgba(16, 16, 16, 0.03) 50%, rgba(24, 24, 24, 0.03) 50%, rgba(24, 24, 24, 0.03) 100%), linear-gradient(205deg, rgba(116, 116, 116, 0.03) 0%, rgba(116, 116, 116, 0.03) 50%, rgba(0, 0, 0, 0.03) 50%, rgba(0, 0, 0, 0.03) 100%), linear-gradient(150deg, rgba(5, 5, 5, 0.03) 0%, rgba(5, 5, 5, 0.03) 50%, rgba(80, 80, 80, 0.03) 50%, rgba(80, 80, 80, 0.03) 100%), linear-gradient(198deg, rgba(231, 231, 231, 0.03) 0%, rgba(231, 231, 231, 0.03) 50%, rgba(26, 26, 26, 0.03) 50%, rgba(26, 26, 26, 0.03) 100%), linear-gradient(278deg, rgba(89, 89, 89, 0.03) 0%, rgba(89, 89, 89, 0.03) 50%, rgba(26, 26, 26, 0.03) 50%, rgba(26, 26, 26, 0.03) 100%), linear-gradient(217deg, rgba(28, 28, 28, 0.03) 0%, rgba(28, 28, 28, 0.03) 50%, rgba(202, 202, 202, 0.03) 50%, rgba(202, 202, 202, 0.03) 100%), linear-gradient(129deg, rgba(23, 23, 23, 0.03) 0%, rgba(23, 23, 23, 0.03) 50%, rgba(244, 244, 244, 0.03) 50%, rgba(244, 244, 244, 0.03) 100%), linear-gradient(135deg, rgb(1, 132, 255), rgb(198, 5, 91));
  display: grid;
  place-content: center;
  border-radius: 20px;
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}