

.img-icon{
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
}

.img-ribbon{
    height: 150px;
}

.img-ribbon-card{
    height: 180px;
    width: 180px;
    max-width: 100%;
    max-height: 100%;
}

.img-profile{
    height: 400px;
    border-radius: 15px;
    max-width: 100%;
    max-height: 100%;
}

.img-tecnicas{
    height: 350px;
    width: 350px;
    max-width: 100%;
    padding-top: 90px;
}

.img-AWS{
    height: 400px;
    width: 400px;
    max-width: 100%;
    padding-top: 70px;
}

.img-otrosConocimientos{
    height: 370px;
    width: 370px;
    max-width: 100%;
    padding-top: 0px;
}

.img-personal{
    height: 65px;
}

.div-center{
    text-align: center;
}

.center {
    margin-left: auto;
    margin-right: auto;
}

.img-otros{
    height: 480px;
    width: 400px;
    max-width: 100%;
}

.img-desc-aws{
    height: 30px;
    vertical-align: auto;
    border-radius: 5px;
}

.img-desc{
    height: 30px;
    vertical-align: auto;
}

@property --rotate{
    syntax: "<angle>";
    initial-value: 132deg;
    inherits: false;
}

.text{
    text-align: justify;
}

.div-center-button{
    position:absolute;
    bottom:0;
    left: 0;
    right: 0;
    margin-top: 10px;
}

.header-bg{
	background: #1e5799; /* Old browsers */
	background: -moz-linear-gradient(top, #09203f 0%, #09203f 50%, #09203f 51%, #537895 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #09203f 0%,#09203f 50%,#09203f 51%,#537895 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #09203f 0%,#09203f 50%,#09203f 51%,#537895 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#7db9e8',GradientType=0 );
}

.grid {
    display: inline-grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 10px;
    padding: 5px;
}
.span-col-2{grid-column: span 2 / auto;}

.grid-title{
    text-align: left;
    font-weight: bold;
    color: hsl(0, 0%, 100%);
}

.grid-image{
    text-align: center;
}

.grid-text{
    text-align: left;
}

.grid-container {
    display: inline-grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 0px;
}

.grid-container > div {
text-align: center;
}

.go-up {
	cursor:pointer;
	position: fixed;
	bottom:20px;
	right:20px;
    text-align: center;
    align-items: center;
    margin: 0 auto;
    z-index: 1;
    -webkit-overflow-scrolling:touch;
    overflow-y: hidden;
}

.img-up {
    height: 70px;
}

.img-ribbonV2{
    height: 80px;
    margin-right: 5px;
}

.checked {
    color: orange;
}

.grid-ingles {
    display: inline-grid;
    grid-template-columns: repeat(1, auto);
    grid-gap: 10px;
    padding: 15px;
}