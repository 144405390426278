.body {
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
    height: 100vh;
    overflow: hidden;
    display: flex;
    font-family: "Anton", sans-serif;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .night {
    position: relative;
    width: 100%;
    height: 100%;
    transform: rotateZ(45deg);
  }
  
  .shooting_star {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 2px;
    background: linear-gradient(-45deg, #5f91ff, rgba(0, 0, 255, 0));
    border-radius: 999px;
    filter: drop-shadow(0 0 6px #699bff);
    -webkit-animation: tail 3000ms ease-in-out infinite, shooting 3000ms ease-in-out infinite;
            animation: tail 3000ms ease-in-out infinite, shooting 3000ms ease-in-out infinite;
  }
  .shooting_star::before {
    content: "";
    position: absolute;
    top: calc(50% - 1px);
    right: 0;
    height: 2px;
    background: linear-gradient(-45deg, rgba(0, 0, 255, 0), #5f91ff, rgba(0, 0, 255, 0));
    transform: translateX(50%) rotateZ(45deg);
    border-radius: 100%;
    -webkit-animation: shining 3000ms ease-in-out infinite;
            animation: shining 3000ms ease-in-out infinite;
  }
  .shooting_star::after {
    content: "";
    position: absolute;
    top: calc(50% - 1px);
    right: 0;
    height: 2px;
    background: linear-gradient(-45deg, rgba(0, 0, 255, 0), #5f91ff, rgba(0, 0, 255, 0));
    transform: translateX(50%) rotateZ(45deg);
    border-radius: 100%;
    -webkit-animation: shining 3000ms ease-in-out infinite;
            animation: shining 3000ms ease-in-out infinite;
    transform: translateX(50%) rotateZ(-45deg);
  }
  .shooting_star:nth-child(1) {
    top: calc(50% - -17px);
    left: calc(50% - 126px);
    -webkit-animation-delay: 7424ms;
            animation-delay: 7424ms;
  }
  .shooting_star:nth-child(1)::before, .shooting_star:nth-child(1)::after {
    -webkit-animation-delay: 7424ms;
            animation-delay: 7424ms;
  }
  .shooting_star:nth-child(2) {
    top: calc(50% - -190px);
    left: calc(50% - 263px);
    -webkit-animation-delay: 9939ms;
            animation-delay: 9939ms;
  }
  .shooting_star:nth-child(2)::before, .shooting_star:nth-child(2)::after {
    -webkit-animation-delay: 9939ms;
            animation-delay: 9939ms;
  }
  .shooting_star:nth-child(3) {
    top: calc(50% - -95px);
    left: calc(50% - 273px);
    -webkit-animation-delay: 6420ms;
            animation-delay: 6420ms;
  }
  .shooting_star:nth-child(3)::before, .shooting_star:nth-child(3)::after {
    -webkit-animation-delay: 6420ms;
            animation-delay: 6420ms;
  }
  .shooting_star:nth-child(4) {
    top: calc(50% - 115px);
    left: calc(50% - 207px);
    -webkit-animation-delay: 8242ms;
            animation-delay: 8242ms;
  }
  .shooting_star:nth-child(4)::before, .shooting_star:nth-child(4)::after {
    -webkit-animation-delay: 8242ms;
            animation-delay: 8242ms;
  }
  .shooting_star:nth-child(5) {
    top: calc(50% - 157px);
    left: calc(50% - 253px);
    -webkit-animation-delay: 1565ms;
            animation-delay: 1565ms;
  }
  .shooting_star:nth-child(5)::before, .shooting_star:nth-child(5)::after {
    -webkit-animation-delay: 1565ms;
            animation-delay: 1565ms;
  }
  .shooting_star:nth-child(6) {
    top: calc(50% - 86px);
    left: calc(50% - 67px);
    -webkit-animation-delay: 22ms;
            animation-delay: 22ms;
  }
  .shooting_star:nth-child(6)::before, .shooting_star:nth-child(6)::after {
    -webkit-animation-delay: 22ms;
            animation-delay: 22ms;
  }
  .shooting_star:nth-child(7) {
    top: calc(50% - -50px);
    left: calc(50% - 137px);
    -webkit-animation-delay: 169ms;
            animation-delay: 169ms;
  }
  .shooting_star:nth-child(7)::before, .shooting_star:nth-child(7)::after {
    -webkit-animation-delay: 169ms;
            animation-delay: 169ms;
  }
  .shooting_star:nth-child(8) {
    top: calc(50% - -188px);
    left: calc(50% - 38px);
    -webkit-animation-delay: 4646ms;
            animation-delay: 4646ms;
  }
  .shooting_star:nth-child(8)::before, .shooting_star:nth-child(8)::after {
    -webkit-animation-delay: 4646ms;
            animation-delay: 4646ms;
  }
  .shooting_star:nth-child(9) {
    top: calc(50% - 75px);
    left: calc(50% - 204px);
    -webkit-animation-delay: 9315ms;
            animation-delay: 9315ms;
  }
  .shooting_star:nth-child(9)::before, .shooting_star:nth-child(9)::after {
    -webkit-animation-delay: 9315ms;
            animation-delay: 9315ms;
  }
  .shooting_star:nth-child(10) {
    top: calc(50% - -80px);
    left: calc(50% - 153px);
    -webkit-animation-delay: 3473ms;
            animation-delay: 3473ms;
  }
  .shooting_star:nth-child(10)::before, .shooting_star:nth-child(10)::after {
    -webkit-animation-delay: 3473ms;
            animation-delay: 3473ms;
  }
  .shooting_star:nth-child(11) {
    top: calc(50% - 41px);
    left: calc(50% - 45px);
    -webkit-animation-delay: 7590ms;
            animation-delay: 7590ms;
  }
  .shooting_star:nth-child(11)::before, .shooting_star:nth-child(11)::after {
    -webkit-animation-delay: 7590ms;
            animation-delay: 7590ms;
  }
  .shooting_star:nth-child(12) {
    top: calc(50% - -118px);
    left: calc(50% - 1px);
    -webkit-animation-delay: 5939ms;
            animation-delay: 5939ms;
  }
  .shooting_star:nth-child(12)::before, .shooting_star:nth-child(12)::after {
    -webkit-animation-delay: 5939ms;
            animation-delay: 5939ms;
  }
  .shooting_star:nth-child(13) {
    top: calc(50% - 132px);
    left: calc(50% - 222px);
    -webkit-animation-delay: 2212ms;
            animation-delay: 2212ms;
  }
  .shooting_star:nth-child(13)::before, .shooting_star:nth-child(13)::after {
    -webkit-animation-delay: 2212ms;
            animation-delay: 2212ms;
  }
  .shooting_star:nth-child(14) {
    top: calc(50% - 69px);
    left: calc(50% - 184px);
    -webkit-animation-delay: 1289ms;
            animation-delay: 1289ms;
  }
  .shooting_star:nth-child(14)::before, .shooting_star:nth-child(14)::after {
    -webkit-animation-delay: 1289ms;
            animation-delay: 1289ms;
  }
  .shooting_star:nth-child(15) {
    top: calc(50% - -9px);
    left: calc(50% - 96px);
    -webkit-animation-delay: 3153ms;
            animation-delay: 3153ms;
  }
  .shooting_star:nth-child(15)::before, .shooting_star:nth-child(15)::after {
    -webkit-animation-delay: 3153ms;
            animation-delay: 3153ms;
  }
  .shooting_star:nth-child(16) {
    top: calc(50% - -38px);
    left: calc(50% - 127px);
    -webkit-animation-delay: 5421ms;
            animation-delay: 5421ms;
  }
  .shooting_star:nth-child(16)::before, .shooting_star:nth-child(16)::after {
    -webkit-animation-delay: 5421ms;
            animation-delay: 5421ms;
  }
  .shooting_star:nth-child(17) {
    top: calc(50% - -119px);
    left: calc(50% - 222px);
    -webkit-animation-delay: 7224ms;
            animation-delay: 7224ms;
  }
  .shooting_star:nth-child(17)::before, .shooting_star:nth-child(17)::after {
    -webkit-animation-delay: 7224ms;
            animation-delay: 7224ms;
  }
  .shooting_star:nth-child(18) {
    top: calc(50% - -65px);
    left: calc(50% - 160px);
    -webkit-animation-delay: 1533ms;
            animation-delay: 1533ms;
  }
  .shooting_star:nth-child(18)::before, .shooting_star:nth-child(18)::after {
    -webkit-animation-delay: 1533ms;
            animation-delay: 1533ms;
  }
  .shooting_star:nth-child(19) {
    top: calc(50% - 62px);
    left: calc(50% - 51px);
    -webkit-animation-delay: 8120ms;
            animation-delay: 8120ms;
  }
  .shooting_star:nth-child(19)::before, .shooting_star:nth-child(19)::after {
    -webkit-animation-delay: 8120ms;
            animation-delay: 8120ms;
  }
  .shooting_star:nth-child(20) {
    top: calc(50% - 193px);
    left: calc(50% - 35px);
    -webkit-animation-delay: 6715ms;
            animation-delay: 6715ms;
  }
  .shooting_star:nth-child(20)::before, .shooting_star:nth-child(20)::after {
    -webkit-animation-delay: 6715ms;
            animation-delay: 6715ms;
  }
  
  @-webkit-keyframes tail {
    0% {
      width: 0;
    }
    30% {
      width: 100px;
    }
    100% {
      width: 0;
    }
  }
  
  @keyframes tail {
    0% {
      width: 0;
    }
    30% {
      width: 100px;
    }
    100% {
      width: 0;
    }
  }
  @-webkit-keyframes shining {
    0% {
      width: 0;
    }
    50% {
      width: 30px;
    }
    100% {
      width: 0;
    }
  }
  @keyframes shining {
    0% {
      width: 0;
    }
    50% {
      width: 30px;
    }
    100% {
      width: 0;
    }
  }
  @-webkit-keyframes shooting {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(300px);
    }
  }
  @keyframes shooting {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(300px);
    }
  }
  @-webkit-keyframes sky {
    0% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(405deg);
    }
  }
  @keyframes sky {
    0% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(405deg);
    }
  }

  
.error-page {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  }
  .error-page h1 {
    font-size: 30vh;
    font-weight: bold;
    position: relative;
    margin: -8vh 0 0;
    padding: 0;
  }
  .error-page h1:after {
    content: attr(data-h1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: transparent;
    /* webkit only for graceful degradation to IE */
    background: -webkit-repeating-linear-gradient(-45deg, #71b7e6, #69a6ce, #b98acc, #ee8176, #b98acc, #69a6ce, #9b59b6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 400%;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.25);
    animation: animateTextBackground 10s ease-in-out infinite;
  }
  .error-page h1 + p {
    color: #d6d6d6;
    font-size: 8vh;
    font-weight: bold;
    line-height: 10vh;
    max-width: 600px;
    position: relative;
  }
  .error-page h1 + p:after {
    content: attr(data-p);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: transparent;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
  }
  .error-page h4 {
    font-size: 3vh;
    font-weight: bold;
    position: relative;
    margin: -8vh 0 0;
    padding: 20;
  }
  .error-page h4:after {
    content: attr(data-h1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: transparent;
    /* webkit only for graceful degradation to IE */
    background: -webkit-repeating-linear-gradient(-45deg, #71b7e6, #69a6ce, #b98acc, #ee8176, #b98acc, #69a6ce, #9b59b6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 400%;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.25);
    animation: animateTextBackground 10s ease-in-out infinite;
  }
  .error-page h4 + p {
    color: #d6d6d6;
    font-size: 8vh;
    font-weight: bold;
    line-height: 10vh;
    max-width: 600px;
    position: relative;
  }
  .error-page h4 + p:after {
    content: attr(data-p);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: transparent;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
  }



  #particles-js {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  
  @keyframes animateTextBackground {
    0% {
      background-position: 0 0;
    }
    25% {
      background-position: 100% 0;
    }
    50% {
      background-position: 100% 100%;
    }
    75% {
      background-position: 0 100%;
    }
    100% {
      background-position: 0 0;
    }
  }
  @media (max-width: 767px) {
    .error-page h1 {
      font-size: 32vw;
    }
    .error-page h1 + p {
      font-size: 6vw;
      line-height: 2vw;
      max-width: 70vw;
    }
  }

  .bn39 {
    background-image: linear-gradient(135deg, #008aff, #86d472);
    border-radius: 6px;
    box-sizing: border-box;
    color: #ffffff;
    display: block;
    height: 50px;
    font-size: 1.4em;
    font-weight: 600;
    padding: 4px;
    position: relative;
    text-decoration: none;
    width: 7em;
    z-index: 2;
    margin: 0 auto;
  }
  
  .bn39:hover {
    color: #fff;
  }
  
  .bn39 .bn39span {
    align-items: center;
    background: #1b2735 0%;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    height: 100%;
    transition: background 0.5s ease;
    width: 100%;
  }
  
  .bn39:hover .bn39span {
    background: transparent;
  }

  .text-emoji {
    color: white;
    font-size: 35px;
  }