html
{
  transition: all 0.3s;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(22, 20, 24);
  color: #eee;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.glass-btn {
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 65px;
  margin-left: 1em;
  margin-right: 1em;  
  cursor: pointer;

  &.blue-btn {
    -webkit-box-shadow: 0px 20px 20px -17px rgba(0, 132, 255, 0.53);
    -moz-box-shadow: 0px 20px 20px -17px rgba(0, 132, 255, 0.53);
    box-shadow: 0px 20px 20px -17px rgba(0, 132, 255, 0.53);

    &:hover {
      -webkit-box-shadow: 0px 20px 35px -16px rgba(0, 132, 255, 0.65);
      -moz-box-shadow: 0px 20px 35px -16px rgba(0, 132, 255, 0.65);
      box-shadow: 0px 20px 35px -16px rgba(0, 132, 255, 0.65);      
    }


  }

  &.green-btn {
    -webkit-box-shadow: 0px 20px 20px -17px rgba(0, 255, 0, 0.5);
    -moz-box-shadow: 0px 20px 20px -17px rgba(0, 255, 0, 0.5);
    box-shadow: 0px 20px 20px -17px rgba(0, 255, 0, 0.5);

    &:hover {
      -webkit-box-shadow: 0px 20px 35px -16px rgba(0, 255, 0, 0.57);
      -moz-box-shadow: 0px 20px 35px -16px rgba(0, 255, 0, 0.57);
      box-shadow: 0px 20px 35px -16px rgba(0, 255, 0, 0.57);      
    }

    .content {
      font-size: 4em;
      font-family: "Roboto", sans-serif;
      background: linear-gradient(to right, #ff1027, #ff4f06 70%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: 0px 10px 37px rgba(255, 16, 39, 0.6);
    }
  }

  &.amber-btn {
    -webkit-box-shadow: 0px 20px 20px -17px rgba(248, 0, 0, 0.53);
    -moz-box-shadow: 0px 20px 20px -17px rgba(248, 0, 0, 0.53);
    box-shadow: 0px 20px 20px -17px rgba(248, 0, 0, 0.53);

    &:hover {
      -webkit-box-shadow: 0px 20px 35px -16px rgba(248, 0, 0, 0.6);
      -moz-box-shadow: 0px 20px 35px -16px rgba(248, 0, 0, 0.6);
      box-shadow: 0px 20px 35px -16px rgba(248, 0, 0, 0.6);      
    }

    .content {
      font-size: 4em;
      font-family: "Roboto", sans-serif;
      background: linear-gradient(to right, #ff8d00, #f7ad07 70%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: 0px 10px 37px rgba(255, 94, 16, 0.7);
    }
  }

  &.purple-btn {
    -webkit-box-shadow: 0px 20px 20px -17px rgba(125,18,255, 0.53);
    -moz-box-shadow: 0px 20px 20px -17px rgba(125,18,255, 0.53);
    box-shadow: 0px 20px 20px -17px rgba(125,18,255, 0.53);

    &:hover {
      -webkit-box-shadow: 0px 20px 35px -16px rgba(125,18,255, 0.6);
      -moz-box-shadow: 0px 20px 35px -16px rgba(125,18,255, 0.6);
      box-shadow: 0px 20px 35px -16px rgba(125,18,255, 0.6);      
    }

    .content {
      font-size: 4em;
      font-family: "Roboto", sans-serif;
      background: linear-gradient(to right, #ff8d00, #f7ad07 70%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: 0px 10px 37px rgba(255, 94, 16, 0.7);
    }
  }

  &:hover {
    background-color: darken(#fafafa, 2%);    
    transform: translateY(-5px);
    transition: all 0.5s;
  }
}
